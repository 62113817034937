import { Dispatch, FC, SetStateAction } from 'react'

import { CheckCircleIcon } from '@heroicons/react/24/outline'

import Button from '../Library/Button/Button'

import { IntegrationLogo } from './IntegrationCard'
import { IntegrationConfig, IntegrationType } from './integrations'

interface IntegrationOptionCardProps {
  disabled: boolean
  integration: IntegrationConfig
  selectedProvider: IntegrationType
  integrationType: IntegrationType
  setSelectedProvider: Dispatch<SetStateAction<IntegrationType>>
}

const IntegrationOptionCard: FC<IntegrationOptionCardProps> = ({
  disabled,
  integration,
  selectedProvider,
  integrationType,
  setSelectedProvider,
}) => {
  const selectedIntegration = selectedProvider === integrationType

  return (
    <>
      <Button
        onClick={() => {
          setSelectedProvider(integrationType as IntegrationType)
        }}
        variant="text"
        className="p-0 !bg-transparent grow h-full text-left"
        disabled={disabled}
      >
        <div
          className={`w-full border grow h-full rounded-lg hover:shadow-lg p-4 flex flex-col gap-10 relative ${
            selectedIntegration
              ? 'bg-indigo-50 border-indigo-500'
              : 'bg-white border-gray-300'
          }
          ${disabled ? 'opacity-40' : ''}
          `}
        >
          <div className="flex gap-4 items-start justify-between">
            <div className="flex items-center gap-4">
              <IntegrationLogo integrationType={integrationType} />
              <p className="text-lg text-gray-800">
                {integration.integrationName}
              </p>
            </div>
            <p>
              <CheckCircleIcon
                className={`w-10 h-10 ${
                  selectedIntegration ? 'text-indigo-500' : 'text-gray-300'
                }`}
              />
            </p>
          </div>

          <div className="flex gap-4 items-center justify-between">
            <ul className="text-sm">
              <p className="text-gray-500 text-xs font-bold">
                Integrates with:
              </p>
              <div className="flex gap-2 flex-wrap mt-2">
                {integration?.features.map((feature, index) => (
                  <li
                    key={index}
                    className={` px-3 py-1 text-xs rounded ${
                      selectedIntegration
                        ? 'bg-indigo-200 text-indigo-600'
                        : 'bg-gray-100 text-gray-500'
                    }`}
                  >
                    {feature}
                  </li>
                ))}
              </div>
            </ul>
          </div>
        </div>
      </Button>
    </>
  )
}

export default IntegrationOptionCard
